import Vue from 'vue';
import {
    Col,
    Row,
    DropdownMenu,
    DropdownItem,
    Icon,
    Grid,
    Uploader,

    GridItem,
    Card,
    Tag,
    Cell,
    CellGroup,
    Toast,
    NavBar,
    Swipe,
    SwipeItem,
    Button,
    Field,
    Picker,
    Popup
} from 'vant';
Vue.use(Col);
Vue.use(Row);
Vue.use(Icon);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Card);
Vue.use(Tag);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(NavBar);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Button);
Vue.use(Field);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Toast);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Uploader);
