import Vue from 'vue'
import VueRouter from 'vue-router'

import center from '../views/center.vue'
import geren from '../views/geren.vue'
import yijian from '../views/yijian.vue'
import tijiao from '../views/tijiao.vue'
import tijiao1 from '../views/tijiao1.vue'
import caina from '../views/caina.vue'
import lishi from '../views/lishi.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'center',
  //   component: center,

  // },
  // {
  //   path: '/',
  //   name: 'geren',
  //   component: geren,

  // }
  {
    path: '/',
    name: "yijian",
    component: yijian
  }

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})




export default router