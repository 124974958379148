<template>
  <div id="app">
    <van-nav-bar title="历史反馈" left-arrow @click-left="onClickLeft" />
    <div class="fx">
      <img
        src="../../src/assets/ciana.png"
        alt=""
        class="yi1"
        v-if="(this.name = '')"
      />
      <img
        src="../../src/assets/wei.png"
        alt=""
        class="yi1"
        v-if="(this.name = 1)"
      />
      <span class="day">2022-3-10 <span class="time">15:36</span> </span>
      <p class="lei">问题类型：</p>
      <p class="day">APP经常闪退、卡顿</p>
      <p class="lei">问题详情：</p>
      <p class="d1">
        使用过程中进行某些时会出现闪退现象，有时候还会出 现卡顿现象。
      </p>
      <img src="../../src/assets/1.png" alt="" class="wt1" />
      <img src="../../src/assets/1.png" alt="" class="wt1" />
      <img src="../../src/assets/1.png" alt="" class="wt1" />
      <img src="../../src/assets/1.png" alt="" class="wt1" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "",
    };
  },
  created() {},
  methods: {
    onClickLeft() {
      this.$router.go(-1);
      console.log("上一页");
    },
  },
};
</script>

<style>
#app {
  background-color: #fff;
  padding: 10px 5px;
}
.fx {
  padding: 10px 10px;
}
.day {
  color: #8d8d8d;
  font-size: 14px;
}
.time {
  padding-left: 5px;
}
.d1 {
  color: #8d8d8d;
  font-size: 14px;
  line-height: 25px;
}
.lei {
  padding: 10px 0px;
  position: relative;
}
.yi1 {
  width: 70px;
  position: absolute;
  left: 315px;
}
.wt1 {
  float: left;
  width: 80px;
  padding: 5px 6px;
}
</style>
