<template>
  <div id="app">
    <van-nav-bar title="提交成功" left-arrow @click-left="onClickLeft" />
    <img src="../../src/assets/zq.png" alt="" class="ses" />
    <h1 class="see">提交成功</h1>
    <p class="y1">已收到你的意见反馈，我们会第一时间进行处理，</p>
    <p class="y2">请耐心等待，随时注意系统消息通知!</p>
    <van-button
      round
      plain
      hairline
      color="#4ACED2"
      size="mini"
      @click="onClickLeft"
      id="fanhui"
      >返回</van-button
    >
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {
    onClickLeft() {
      this.$router.go(-1);
      console.log("上一页");
    },
  },
};
</script>

<style>
#app {
  background-color: #fff;
  padding: 10px 5px;
}
.ses {
  width: 100px;
  padding: 80px 145px 40px;
}
.see {
  font-size: 20px;
  padding: 0px 150px 20px;
}
.y1 {
  padding: 0px 21px 10px;
  color: #b3b3b3;
}
.y2 {
  padding: 0px 57px 15px;
  color: #b3b3b3;
}
#fanhui {
  padding: 20px 55px;
  margin: 80px 123px;
}
</style>
