<template>
  <div id="app">
    <van-nav-bar title="提交失败" left-arrow @click-left="onClickLeft" />
    <img src="../../src/assets/sb.png" alt="" class="ses" />
    <h1 class="see">提交失败</h1>
    <p class="y3">很抱歉，由于不知名原因提交失败，</p>
    <p class="y4">请检查你的网络状态是否通畅，反馈是否填写完整…</p>

    <van-button
      round
      plain
      hairline
      color="#4ACED2"
      size="mini"
      @click="onClickLeft"
      id="fanhui"
      >返回</van-button
    >
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {
    onClickLeft() {
      this.$router.go(-1);
      console.log("上一页");
    },
  },
};
</script>

<style>
#app {
  background-color: #fff;
  padding: 10px 5px;
}
.ses {
  width: 100px;
  padding: 80px 145px 40px;
}
.see {
  font-size: 20px;
  padding: 0px 150px 20px;
}
.y3 {
  padding: 0px 69px 10px;
  color: #b3b3b3;
}
.y4 {
  padding: 0px 14px 15px;
  color: #b3b3b3;
}
#fanhui {
  padding: 20px 55px;
  margin: 80px 123px;
}
</style>
