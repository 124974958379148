<template>
  <div class="xieyi">
    <div class="title">
      <h3 style="text-align: center; height: 40px; margin-top: 15px">
        用户协议
      </h3>
    </div>
    <div>
      <p>欢迎您使用“预见”软件及相关服务！</p>
      <p><br /></p>
      <p>
        为了更好地为您提供服务，请您仔细阅读《“预见”用户协议》（以下简称“本协议”）。在您开始使用“预见”软件及相关服务之前，请您务必认真阅读并充分理解本协议，特别是涉及免除或者限制责任的条款、权利许可和信息使用的条款、同意开通和使用特殊单项服务的条款、法律适用和争议解决条款等。除非您完全接受本协议的全部内容，否则您无权下载、安装、注册、登录、使用（以下统称“使用”）“预见”软件，或者通过任何方式使用“预见”服务，或者获得“预见”软件提供的任何服务
      </p>
      <p><br /></p>
      <p>
        （本协议中统称“使用”）。若您使用“预见”软件及相关服务，则视为您已充分理解本协议并承诺作为本协议的一方当事人接受本协议的约束。
      </p>
      <p><br /></p>
      <p>一、适用范围</p>
      <p><br /></p>
      <p>
        1.1本协议是您与预见未来(辽宁)计算机软件科技有限公司（以下简称“公司”或“我们”）之间就您下载、安装、注册、登录、使用“预见”软件，或／并获得“预见”软件提供的相关服务所订立的协议。公司有权依提供或运营“预见”软件及相关服务的需要单方决定，安排或指定其关联公司、控制公司、继承公司或公司认可的第三方继续运营“预见”软件。本协议下涉及的服务，公司有权安排或指定由公司的关联公司、控制公司、继承公司或公司认可的第三方向您提供。您知晓并同意接受相关服务内容，即视为接受相关权利义务关系亦受本协议约束。
      </p>
      <p><br /></p>
      <p>
        1.2“用户”指所有直接或间接获取和使用“预见”软件及相关服务的使用者，包括自然人、法人和其他组织等，在本协议中称为“用户”或称“您”。
      </p>
      <p><br /></p>
      <p>
        1.3“预见”指由公司拥有并运营的、标注名称为“预见”的软件（包括但不限于客户端应用程序等，以下简称“软件”）以及网站（包括但不限于域名为www.smds100.com的网站，以下简称“网站”）。公司有权对软件及网站名称或域名单方变更（包括但不限于更名、新增等）。
      </p>
      <p><br /></p>
      <p>二、使用“预见”软件及相关服务</p>
      <p><br /></p>
      <p>
        2.1您使用“预见”软件及相关服务，可以通过预装、公司已授权的第三方下载等方式获取“预见”软件或访问预见相关网站。若您并非从公司或经公司授权的第三方获取“预见”软件的，公司无法保证其预见能够正常使用，您因此遭受的损失与公司无关。
      </p>
      <p><br /></p>
      <p>
        2.2公司可能为不同的终端设备开发了不同的软件版本，您应当根据实际设备状况获取、下载、安装合适的版本。
      </p>
      <p><br /></p>
      <p>
        2.3您可根据需要自行使用“预见”软件及相关服务或更新“预见”软件版本，如您不再需要使用“预见”软件及相关服务可自行卸载。
      </p>
      <p><br /></p>
      <p>
        2.4为更好地提升用户体验及服务，公司将不定期提供软件及相关服务更新或改变（包括但不限于软件修改、升级、功能强化、开发新服务、软件替换等）。为保证“预见”软件及相关服务安全、提升用户服务，本软件及相关服务更新后，在可能的情况下，公司将以包括但不限于系统提示、公告等方式提示用户，用户有权选择接受或不接受软件及相关服务更新；如用户不接受更新，本软件及相关服务部分或全部功能将受到限制或不能继续使用。
      </p>
      <p><br /></p>
      <p>
        2.5除非得到公司明示事先书面授权，您不得以任何形式对“预见”软件及相关服务进行包括但不限于改编、复制、镜像或交易等未经授权的访问或使用。
      </p>
      <p><br /></p>
      <p>
        2.6您理解：您使用“预见”软件及相关服务需自行准备与软件及相关服务有关的终端设备（如电脑、手机等装置），一旦您在其终端设备中打开“预见”软件或访问预见相关网站，即视为您使用“预见”软件及相关服务。为充分实现“预见”的全部功能，您可能需要将其终端设备联网，由您承担所需要的费用（包括但不限于流量费、上网费等）。
      </p>
      <p><br /></p>
      <p>
        2.7公司许可您个人的、可撤销的、不可转让的、非独占地和非商业的合法使用“预见”软件及相关服务的权利。本协议未明示授权的其他一切权利仍由公司保留，您在行使该些权利时须另行获得公司的书面许可，同时公司如未行使前述任何权利，并不构成对该权利的放弃。
      </p>
      <p><br /></p>
      <p>
        2.8预见为使您更好地使用“预见”软件及相关服务，保障您的账号安全，某些功能和／或服务项目（包括但不限于购买供品服务等）需要注册和登录后方可使用。
      </p>
      <p><br /></p>
      <p>
        2.9如您发现“预见”软件及网站内存在任何侵犯您权利的内容，请立即通过邮箱service@smds100.com或公司设置的其他渠道通知公司，提供您有相关权利的初步证据，公司将会根据当地法律规定及时处理您的投诉。
      </p>
      <p><br /></p>
      <p>
        三、关于账号3.1“预见”为用户提供了注册通道，用户设置的账号是用户用以登录并以注册用户身份使用“预见”软件及相关服务的凭证。
      </p>
      <p><br /></p>
      <p>
        3.2您理解并承诺：您所设置的账号不得违反国家法律法规及公司的相关规则，您的账号名称、头像等注册信息及其他个人信息中不得出现违法和不良信息，未经他人许可不得用他人名义（包括但不限于冒用他人姓名、名称、字号、头像等足以让人引起混淆的方式）开设账号，不得恶意注册“预见”账号（包括但不限于频繁注册、批量注册账号等行为）。您在账号注册及使用过程中需遵守相关法律法规，不得实施任何侵害国家利益、损害其他公民合法权益，有害社会道德风尚的行为。公司有权对您提交的注册信息进行审核。如果您以虚假信息骗取账号名称注册，或其账号头像等注册信息存在违法和不良信息的，公司有权采取通知限期改正、暂停使用、注销登记等措施。
      </p>
      <p><br /></p>
      <p>
        3.3您在“预见”中的注册账号所有权及有关权益均归公司所有，您完成注册手续后仅享有该账号的使用权。您的账号仅限于您本人使用，未经公司书面同意，禁止以任何形式赠与、借用、出租、转让、售卖或以其他方式许可他人使用该账号。如果公司发现或者有合理理由认为使用者并非账号初始注册人，公司有权在未通知您的情况下，暂停或终止向该注册账号提供服务，并有权注销该账号，而无需承担任何法律责任。
      </p>
      <p><br /></p>
      <p>
        3.4您有责任维护个人账号的安全性与保密性，并对您以注册账号名义所从事的活动承担全部法律责任，包括但不限于您在“预见”上进行的任何数据修改、款项支付等操作行为。您应高度重视对账号的保密，在任何情况下不向他人透露账号信息。若发现他人未经许可使用您的账号或发生其他任何安全漏洞问题时，您应当立即通知公司。
      </p>
      <p><br /></p>
      <p>
        3.5在注册、使用和管理账号时，您应保证注册账号时填写的身份信息的真实性，请您在注册、管理账号时使用真实、准确、合法、有效的相关身份证明材料及必要信息（包括但不限于您的姓名、联系电话等）。依照国家法律法规的规定，为使用本软件及服务，您需要填写真实的身份信息，并注意及时更新上述相关信息。若您提交的材料或提供的信息不准确、不真实、不合法或者公司有理由怀疑为错误、不实或不合法的资料，则公司有权拒绝为您提供相关服务或您可能无法使用“预见”软件及相关服务或在使用过程中部分功能受到限制。
      </p>
      <p><br /></p>
      <p>
        3.6除自行注册“预见”账号外，您也可选择通过授权使用您合法拥有的公司和／或其关联公司其他软件用户账号，以及公司认可的实名注册的第三方软件或平台用户账号注册、登录或使用“预见”软件及相关服务，但第三方软件或平台对此有限制或禁止的除外。当您以前述方式注册、登录或使用“预见”软件及相关服务，视为您已承诺授权使用的相应账号已进行实名注册登记，同时视为您同意受本协议内容的约束。
      </p>
      <p><br /></p>
      <p>
        3.7您通过已注册或者已同步的账号登录公司和／或关联公司运营的其他软件产品及网站时（如有），应遵守其他软件产品及网站自身的《用户协议》
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.xieyi {
  font-size: 14px;
  width: 95%;
  /* width: 300px; */
  margin: 0 auto;
}
.title {
  width: 200px;
  margin: 0 auto;
  text-align: center;
}
p {
  text-indent: 20px;
}
</style>
