<template>
  <div id="app">
    <p>
      预见app集成了Beizi广告，点击查看Beizi广告
      <span @click="yinsi" style="color: blue">《隐私协议》</span>
    </p>
    <p>
      <b style="font-weight: 700; font-size: 16px">【前言】</b
      >预见app由预见未来（辽宁）计算机软件科技有限公司负责开发（以下统称“预见app”或“我们”）一向庄严承诺保护使用预见app的产品和服务（以下统称“预见app服务”）之用户（以下统称“用户”或“您”）的个人信息和隐私安全。您在使用预见app服务时，我们可能会收集和使用您的相关个人信息。我们希望通过《预见app隐私政策》（以下简称“本政策”）向您说明我们在收集和使用您相关个人信息时对应的处理规则等相关事宜，以便更好地保障您的权益。
    </p>
    <p style="font-weight: 700; font-size: 16px">
      <b>【特别提示】本政策适用于我们提供的所有</b><b>预见app</b
      ><b
        >服务。当您使用我们任何单项服务时，您同意接受本政策以及我们在该单项服务中设置的特定隐私信息类政策条款（以下称“特定条款”）的保护，在此情况下特定条款与本政策条款同时对您产生效力。未满十四周岁的用户，同时适用本政策以及《</b
      ><b>预见app</b
      ><b
        >儿童个人信息保护规则及监护人须知》。如特定条款与本政策条款存在同类条款的不一致约定，则在特定条款约束范围内应以特定条款为准。如我们提供的某一单项服务不适用本政策的，该服务中会以适当方式明示排除适用本政策。</b
      >
    </p>
    <p>
      <b>在使用各项</b><b>预见app</b
      ><b
        >服务前，请您务必仔细阅读并透彻理解本政策，特别是以粗体标识的条款，您应重点阅读，在确认充分理解并同意后再开始使用。如果您不同意本政策的内容，将可能导致</b
      ><b>预见app</b
      ><b
        >服务无法正常运行，或者无法达到我们拟达到的服务效果，您应立即停止访问/使用</b
      ><b>预见app</b><b>服务。</b>
    </p>
    <p>
      在阅读完本政策后，如您对本政策或与本政策相关的事宜有任何问题，您可通过<b>本政策“如何联系我们”章节</b>所列的反馈渠道联系我们，我们会尽快为您作出解答。
    </p>
    <p><b>本政策将帮助您了解以下内容：</b></p>
    <p style="font-weight: 700; font-size: 16px">
      <b>一、我们如何收集和使用个人信息</b>
    </p>
    <p style="font-weight: 700; font-size: 16px">
      <b>二、我们如何使用COOKIES或同类技术</b>
    </p>
    <p style="font-weight: 700; font-size: 16px">
      <b>三、我们可能共享、转让和披露的个人信息</b>
    </p>
    <p style="font-weight: 700; font-size: 16px">
      <b>四、我们如何存储和保护个人信息</b>
    </p>
    <p style="font-weight: 700; font-size: 16px">
      <b>五、如何管理您的个人信息</b>
    </p>
    <p style="font-weight: 700; font-size: 16px"><b>六、第三方服务</b></p>
    <p style="font-weight: 700; font-size: 16px"><b>七、未成年人保护</b></p>
    <p style="font-weight: 700; font-size: 16px"><b>八、修订和通知</b></p>
    <p style="font-weight: 700; font-size: 16px"><b>九、如何联系我们</b></p>
    <p style="font-weight: 700; font-size: 16px">
      <b>一、我们如何收集和使用个人信息</b>
    </p>
    <p>
      我们收集您的个人信息主要是为了您和其他用户能够更容易和更满意地使用预见app服务。预见app的目标是向所有的互联网用户提供安全、有趣及有教益的上网经历，而这些信息有助于我们实现这一目标。
    </p>
    <p>（一）我们将通过以下途径收集和获得您的个人信息：</p>
    <p>1、您提供的信息。例如：</p>
    <p>（1）您在注册预见app服务的帐号或使用预见app服务时，向我们提供的信息；</p>
    <p>
      （2）您通过预见app服务向第三方提供的共享信息，以及您使用预见app服务时所存储的信息。
    </p>
    <p>
      <b>请注意，如您在</b><b>预见app</b
      ><b
        >服务中其他用户可见的公开区域内上传或发布的信息中、您对其他人上传或发布的信息作出的回应中公开您的信息，该等信息可能会被他人收集并加以使用。当您发现他人不正当地收集或使用您的信息时，可通过本政策“如何联系我们”章节所列的反馈渠道联系我们。</b
      >
    </p>
    <p>
      2、第三方共享的您的信息。亦即第三方使用预见app服务时所提供有关您的共享信息。
    </p>
    <p>
      3、我们获取的您的信息。您在使用预见app服务时，我们收集、汇总、记录的信息，例如日志信息、位置信息、设备信息。
    </p>
    <p>（二）我们会出于以下目的，收集和使用您以下类型的个人信息：</p>
    <p>
      <b
        >我们需要特别提醒您的是：由于我们的产品和服务较多，为您提供的内容也不同，因此相关业务功能（包括其收集的您的个人信息类型）也会因您选择的产品/服务的内容不同而有所区别，具体请您以实际使用的产品/服务为准。</b
      >除此之外，您理解并同意，我们希望提供给您的产品和服务是完善的，所以我们会不断改进我们的产品和服务，这意味着随着产品和服务的迭代、拓展、升级等，我们可能会经常推出新的服务或业务功能或重新划分业务功能，并可能需要收集新的个人信息或变更个人信息使用目的或方式。<b>如果我们要将您的个人信息用于本政策中未载明的其他用途或额外收集未提及的其他个人信息，我们会依法通过页面提示、交互流程、网站公告或其他方式另行征得您的同意。</b>在此过程中，您可通过<b>本政策“如何联系我们”章节</b>所列的反馈渠道联系我们，我们会尽快为您作出解答。
    </p>
    <p><b>1、帮助您完成注册及登录</b></p>
    <p>
      为便于我们为您提供持续稳定的服务并保障您使用预见app服务的安全性，<b>我们需要您提供基本注册或登录信息，包括手机号码、电子邮箱地址，并创建您的帐号、用户名和密码。</b>在部分单项服务中，如果您仅需使用浏览、搜索等基本功能，您不需要注册成为预见app用户及提供上述信息。
    </p>
    <p>
      您也可以在注册、登录或后续使用过程中，<b>填写或补充您的其他额外信息（例如您的昵称、头像、性别、籍贯、职业、学历、出生日期、兴趣爱好）</b>，这将有助于我们给您提供个性化和更优的服务体验，但如果您不提供这些信息，并不会影响您使用预见app服务的基本功能。
    </p>
    <p><b>2、实现身份认证</b></p>
    <p>
      依照相关法律法规规定及监管要求，或为满足用户身份真实核验、保障系统和服务安全及其他特定服务功能等需要，<b>我们可能需要您提供真实身份信息（例如姓名、身份证、护照、驾驶证、户口本）、生物识别信息（静态或动态）及其他身份信息以完成身份认证</b>。如果您不提供上述信息，我们将不能向您提供相关的功能及服务。
    </p>
    <p>
      为实现身份认证的目的，<b>您同意并授权我们可能以自行或委托第三方向有关身份认证机构（如个人征信机构、政府部门等）提供、查询、核对您的前述身份信息</b>。
    </p>
    <p>
      <b
        >请注意，您的身份信息、生物识别信息等属于敏感个人信息，请您谨慎提供，如果拒绝提供您将可能无法获得相关服务，但不影响其他功能与服务的正常使用。</b
      >
    </p>
    <p><b>3、维护基本功能的正常运行</b></p>
    <p>
      在您使用我们服务过程中，为向您提供浏览、搜索等基本功能，并识别帐号异常状态、了解产品适配性、保障预见app服务的网络及运营安全，以维护前述基本功能的正常运行，<b
        >我们可能会直接或者间接地收集、存储关于您使用的服务以及使用方式的信息并将这些信息进行关联，这些信息包括：</b
      >
    </p>
    <p>
      <b
        >（1）日志信息：当您使用我们的服务时（包括后台运行状态），我们可能会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。例如:您的登录帐号、搜索查询内容、IP地址、浏览器的类型、电信运营商、网络环境、使用的语言、访问日期和时间及您访问的网页浏览记录、Push打开记录、停留时长、刷新记录、发布记录、关注、订阅、收藏及分享。</b
      >
    </p>
    <p>
      <b
        >（2）设备信息：当您使用我们的服务时（包括后台运行状态），我们可能会接收并记录您所使用的设备相关信息。例如：设备机型、操作系统及版本、客户端版本、设备分辨率、包名、设备设置、进程及软件列表、设备标识符（MAC地址/IMEI/Android
        ID/IDFA/OpenUDID/GUID/SIM&nbsp;卡IMSI&nbsp;信息）、软硬件特征信息、设备所在位置相关信息（例如IP&nbsp;地址、GPS位置以及能够提供相关信息的WLAN接入点、蓝牙和基站传感器信息）。</b
      >为了收集上述基本的个人设备信息，我们将会申请访问您的设备信息的权限，我们收集这些信息是为了向您提供我们基本服务和基础功能，如您拒绝提供上述权限将可能导致您无法使用预见app服务。
    </p>
    <p>
      <b
        >请注意，单独的设备信息、日志信息是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。这些信息在结合使用期间，将作为您的个人信息按照本政策处理与保护。</b
      >
    </p>
    <p>
      <b>4、向您提供互动与发布服务</b>
    </p>
    <p>
      您在使用预见app服务时、上传和/或发布信息以及进行相关互动行为（例如<b>发布跟贴、回复跟贴、顶贴、喜欢文章、发布讲讲、分享、收藏、打赏</b>）时，<b>我们将收集您上传、发布或形成的信息，并使用您的昵称、头像和发布内容以作展示</b>。
    </p>
    <p>
      <b
        >请注意，您公开发布的信息中可能包含您的个人信息或敏感个人信息，请您在发布前慎重考虑。如您发布的信息涉及到他人的个人信息的，您应当在发布前征得他人同意。</b
      >
    </p>
    <p>
      <b>5、帮助您完成下单、支付并向您交付商品或服务</b>
    </p>
    <p>
      （1）当您在预见app服务中订购具体商品及/或服务时，我们会通过系统为您生成购买该商品及/或服务的订单。<b>在下单过程中，您至少需提供收货人个人身份信息、姓名、收货地址、邮政编码、收货人、联系电话、支付状态信息</b>。如果您拒绝提供此类信息，我们将无法完成相关交付服务。如您通过预见app服务为其他人订购商品或服务，您需要提供该实际订购人的前述信息。<b>向我们提供该实际订购人的前述信息之前，您需确保您已经取得其授权同意</b>；
    </p>
    <p>
      （2）为向您展示帐号的交易信息并保障交易安全，我们会收集您在使用预见app服务过程中产生的<b>信息（例如您所购买的商品或服务信息、具体订单号、订单创建时间、交易金额）</b>用于向您展示及便于您对订单进行管理；
    </p>
    <p>
      （3）为完成订单支付、交付商品或服务、确认交易状态及为您提供售后与争议解决服务，<b
        >我们会通过您基于交易所选择的交易对象、支付机构、物流公司等收集与交易进度相关的您的帐号、订单、交易、支付、物流信息，或将您的交易信息共享给上述服务提供者。</b
      >
    </p>
    <p>
      <b>6、为您提供客服或其他用户响应功能</b>
    </p>
    <p>
      当您联系我们的客服或使用其他用户响应功能时（例如提出售中售后申请、个人信息保护投诉或建议、其他客户投诉和需求），<b>我们可能需要您提供必要的个人信息以匹配并核验您的用户身份</b>，以便保障您的帐号与系统安全。<b>我们可能还会保存您的联系方式（您与我们联系时使用的或您向我们主动提供的其他联系方式）、您与我们的通信/通话记录和内容、与您需求相关联的其他必要信息</b>，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。
    </p>
    <p>
      <b>7、我们通过间接方式收集到的您的个人信息：</b>
    </p>
    <p>
      基于您选择使用的预见app服务，我们可能从关联方、第三方合作伙伴获取您授权共享的相关信息。例如，当您使用第三方平台的帐号登录预见app服务时，我们将根据您的授权获取该第三方帐号下的相关信息（包括：用户名、昵称、头像，具体以您的授权内容为准），<b
        >并在您同意本政策后将您的第三方帐号与您的</b
      ><b>预见app</b><b>服务帐号绑定，使您可以通过第三方帐号直接登录并使用</b
      ><b>预见app</b
      ><b>服务</b
      >。我们将在符合相关法律法规规定，并依据与关联方或第三方合作伙伴的约定、确信其提供的信息来源合法的前提下，收集并使用您的这些信息。
    </p>
    <p>
      <b>8、向您提供商品或服务的信息展示和推送</b>
    </p>
    <p>
      基于您向我们提供的信息、我们可能收集的信息及我们通过间接方式收集到的您的信息（例如<b
        >您的浏览及搜索记录、设备信息、位置信息、订单信息），我们可能会基于上述一项或几项信息的结合，进行推荐算法建模、程序化广告推荐算法建模、用户行为分析及用户画像，用于提取您的浏览、搜索偏好、行为习惯、位置信息相关特征，以便向您提供更契合您需求的页面展示和推荐个性化内容。我们也可能基于特征标签通过电子邮件、短信或其他方式向您发送营销信息、用户调研，帮助我们改善</b
      ><b>预见app</b><b>服务以及提供或推广我们/第三方的如下商品和服务：</b>
    </p>
    <p>
      我们的商品和服务，包括但不限于：即时通讯服务、网上媒体服务、互动娱乐服务、社交网络服务、支付服务、互联网搜索服务、位置和地图服务、应用软件和服务、数据管理软件和服务、网上广告服务、互联网金融及其他社交媒体、娱乐、网络游戏、电子商务、资讯及通讯软件和服务；及
    </p>
    <p>
      第三方商品和服务，包括但不限于：互联网服务、食物和餐饮、体育、音乐、电影、电视、现场表演和其他艺术和娱乐、书册、杂志和其他刊物、服装和配饰、珠宝、化妆品、个人健康和卫生、电子、收藏品、家用器皿、电器、家居装饰和摆设、宠物、汽车、酒店、交通和旅游、银行、保险及金融服务、积分和奖励计划，以及我们认为可能与您相关的其他商品和服务。
    </p>
    <p>
      <b
        >如您想管理我们的个性化服务，您可以根据您所使用的单项服务提供的指引予以操作。</b
      >
    </p>
    <p><b>9、为您提供安全保障</b></p>
    <p>
      <b
        >为提高您使用我们及合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或</b
      ><b>预见app</b
      ><b
        >服务相关协议规则的情况，我们可能会收集、使用或整合您的帐号信息、交易信息、设备信息、日志信息以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息，来综合判断您帐号及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。</b
      >
    </p>
    <p>
      <b>10、</b
      >您理解并同意，部分单项服务可能需要您在您的设备中开启特定的访问权限，以实现这些权限所涉及信息的收集和使用。例如：
    </p>
    <p>
      <b
        >（1）在您开启存储权限后，您允许我们访问您的存储空间，以便使您可以下载并保存内容，或者通过缓存来实现相应服务等功能；</b
      >
    </p>
    <p>
      <b
        >（2）在您开启设备信息权限后，您允许我们获取您的设备信息以作为您设备的唯一性标识，以便向您提供更契合您需求的页面展示和推荐个性化内容、了解产品适配性、识别异常状态以及保障</b
      ><b>预见app</b><b>服务的网络及运营安全；</b>
    </p>
    <p>
      <b
        >（3）在您开启相册和/或相机（摄像头）权限后，您能够上传、拍摄照片/图片/视频，实现发布信息和评论、视频直播或与客服沟通提供证明等功能；</b
      >
    </p>
    <p>
      <b
        >（4）在您开启通讯录权限后，我们可以获取您的通讯录好友信息，以便向您提供好友状态查询、邀请您的好友使用</b
      ><b>预见app</b><b>服务、向您认识的人分享内容/产品等功能；</b>
    </p>
    <p>
      <b
        >（5）在您开启麦克风和相关语音权限后，您能够使用语音功能与我们的产品、他人进行信息交互，或与客服联系等；</b
      >
    </p>
    <p>
      <b
        >（6）在您开启位置权限后，我们可以获取您的位置信息，以便让您与附近的朋友进行信息交互或向您推送个性化内容等；</b
      >
    </p>
    <p>
      <b
        >（7）在您开启我们可读取/写入您日历的权限后，我们可以获取您的日程时间，以便为您提供记录、预约、提醒等功能。</b
      >
    </p>
    <p>
      当您需要关闭这些功能权限时，大多数移动设备都会支持您的这项需求，具体方法请参考或联系您移动设备的服务商或生产商。请您注意，您开启任一权限即代表您授权我们可以收集和使用相关信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。
    </p>
    <p>
      <b>11、征得授权同意的例外</b>
    </p>
    <p>根据相关法律法规规定，以下情形中收集您的信息无需征得您的授权同意：</p>
    <p>（1）与国家安全、国防安全直接相关的；</p>
    <p>（2）与公共安全、公共卫生、重大公共利益直接相关的；</p>
    <p>（3）与刑事侦查、起诉、审判和判决执行等直接相关的；</p>
    <p>
      （4）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
    </p>
    <p>（5）所收集的个人信息是您自行向社会公众公开的；</p>
    <p>（6）根据您的要求签订和履行合同所必需的；</p>
    <p>
      （7）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
    </p>
    <p>
      （8）维护预见app服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；
    </p>
    <p>（9）开展合法的新闻报道所必需的；</p>
    <p>
      （10）出于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的信息进行去标识化处理的；
    </p>
    <p>（11）法律法规规定的其他情形。</p>
    <p>
      <b>12、有关敏感个人信息的提示</b>
    </p>
    <p>
      <b
        >敏感个人信息，是指一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息。以上由您提供或我们收集您的信息中，可能包含您的敏感个人信息，例如身份证件号码、个人生物识别信息（静态或动态）、银行账号、通信记录和内容、财产信息、行踪轨迹、住宿信息、交易信息。请您谨慎并留意敏感个人信息，您同意我们可以按本政策所述的目的和方式来处理您的敏感个人信息。</b
      >
    </p>
    <p style="font-weight: 700; font-size: 16px">
      <b>二、我们如何使用COOKIES或同类技术</b>
    </p>
    <p>
      我们或我们的合作伙伴可能通过COOKIES或同类技术获取和使用您的信息，并将该等信息存储为日志信息。
    </p>
    <p>
      通过使用COOKIES，我们向用户提供简单易行并富个性化的网络体验。一个COOKIES是少量的数据，它们从一个网络服务器送至您的浏览器并存在计算机硬盘上。我们使用COOKIES是为了让其用户可以受益。比如，为使得预见app虚拟社区的登录过程更快捷，您可以选择把用户名存在一个COOKIES中。这样下次当您要登录预见app的服务时能更加方便快捷。COOKIES能帮助我们确定您连接的页面和内容，您在预见app特定服务上花费的时间和您所选择的预见app服务。
    </p>
    <p>
      COOKIES使得我们能更好、更快地为您服务，并且使您在预见app服务上的经历更富个性化。然而，您应该能够控制COOKIES是否以及怎样被您的浏览器接受。请查阅您的浏览器附带的文件以获得更多这方面的信息。
    </p>
    <p>
      我们和第三方合作伙伴可能通过COOKIES或同类技术收集和使用您的信息，并将该等信息存储。
    </p>
    <p>我们使用自己的COOKIES或同类技术，可能用于以下用途：</p>
    <p>
      1、记住您的身份。例如：COOKIES或同类技术有助于我们辨认您作为我们的注册用户的身份，或保存您向我们提供有关您的喜好或其他信息；
    </p>
    <p>
      2、分析您使用我们服务的情况。我们可利用COOKIES或同类技术来了解您使用预见app服务进行什么活动、或哪些服务最受欢迎；
    </p>
    <p>
      3、广告优化。COOKIES或同类技术有助于我们根据您的信息，向您提供与您相关的广告而非进行普遍的广告投放。
    </p>
    <p>
      我们为上述目的使用COOKIES或同类技术的同时，可能将通过COOKIES或同类技术收集的非个人身份信息汇总提供给广告商和其他伙伴，用于分析您和其他用户如何使用预见app服务并用于广告服务。
    </p>
    <p>
      预见app服务上可能会有广告商和其他合作方放置的COOKIES或同类技术。这些COOKIES和或同类技术可能会收集与您相关的非个人身份信息，以用于分析用户如何使用该等服务、向您发送您可能感兴趣的广告，或用于评估广告服务的效果。这些第三方COOKIES或同类技术收集和使用该等信息不受本政策约束，而是受到其自身的信息保护声明约束，我们不对第三方的COOKIES或同类技术承担责任。
    </p>
    <p>
      <b
        >您可以通过浏览器或用户选择机制拒绝或管理COOKIES或同类技术。但请您注意，如果您停用COOKIES或同类技术，我们有可能无法为您提供最佳的服务体验，某些服务也可能无法正常使用。同时，您仍然将收到广告，只是这些广告与您的相关性会降低。</b
      >
    </p>
    <p style="font-weight: 700; font-size: 16px">
      <b>三、我们可能共享、转让或披露的个人信息</b>
    </p>
    <p><b>（一）共享</b></p>
    <p>
      我们非常重视保护您的个人信息。除以下情形外，未经您另行同意，我们不会与预见app之外的任何第三方共享您的个人信息：
    </p>
    <p>
      1、<b>为实现我们的服务/功能所必需进行的共享</b>。在某些情况下，我们需要向第三方共享您的信息，以实现我们产品与/或服务的功能，让您正常使用您需要的服务，包括：（1）向提供支付服务的第三方支付机构共享对应的<b>交易订单信息</b>和<b>支付金额信息</b>；（2）向入驻预见app平台的第三方商家和物流服务商共享<b>订单信息</b>；（3）向实名认证服务商共享<b>实名认证所需资料</b>；（4）其他与我们的服务/功能相关的供应商、服务提供商和业务合作伙伴所必需进行的共享。
    </p>
    <p>
      2、<b>与我们的部分合作伙伴的必要共享</b>。为了必要/合理的业务的顺利开展、满足您的要求、履行我们在相关用户协议或本政策中的义务和行使我们的权利或遵守法律规定等目的，我们可能需要向部分合作伙伴共享您的信息，包括：（1）向提供数据服务（包括网络广告监测、数据统计、数据分析等）的合作伙伴共享<b>日志信息</b>、<b>设备信息</b>；（2）向提供产品分析服务（包括应用崩溃分析、网络性能分析、域名解析优化）的合作伙伴共享<b>您的产品使用情况（如崩溃、闪退）的统计性数据</b>；（3）向提供营销服务（包括广告投放、市场营销）的合作伙伴共享您的相关信息（包括：<b>设备信息</b>）；（4）向委托我们进行推广的合作伙伴共享有多少人看了他们的推广信息或在看到这些信息后购买了的商品，或者向他们提供<b>不能识别个人身份的统计信息</b>，帮助他们了解其受众或顾客；（5）其他与我们的部分合作伙伴的必要共享。
    </p>
    <p>
      3、<b>与我们的关联方的必要共享</b>。为便于我们基于统一的帐号体系向您提供一致化服务以及便于您进行统一管理、个性化推荐、保障系统和帐号安全等，您的个人信息可能会在我们和我们的关联方之间进行必要共享，包括：1）为了帮助您节省时间、简化操作流程，您在注册我们的预见app手机帐号/邮箱帐号后无需重复注册即可登录部分单项服务，您的帐号相关信息会在我们和我们的关联方的产品内部进行必要共享；2）为了使您在我们的及我们关联方产品与/或服务有一致性和个性化的浏览、广告等服务体验，您知悉并同意，您的帐号信息、设备信息、相关浏览数据等反映您使用习惯和爱好的信息可能会与我们的关联方共享。
    </p>
    <p>4、实现本政策第一条“我们如何收集和使用个人信息”部分所述的其他目的。</p>
    <p>5、履行我们在本政策或我们与您达成的其他协议中的义务和行使我们的权利。</p>
    <p>
      6、在法律法规允许的范围内，为了遵守法律、维护我们及我们的关联方或合作伙伴、您或其他预见app用户或社会公众利益、财产或安全免遭损害，比如为防止欺诈等违法活动和减少信用风险，我们可能与其他公司和组织交换信息。不过，这并不包括违反本政策中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的信息。
    </p>
    <p>7、应您合法需求或经您的授权同意。</p>
    <p>8、应您的监护人合法要求而提供您的信息。</p>
    <p>
      9、根据与您签署的单项服务协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供。
    </p>
    <p>10、基于学术研究而提供。</p>
    <p>11、基于符合法律法规的社会公共利益而提供。</p>
    <p>
      <b
        >我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息。对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本政策以及其他任何相关的保密和安全措施来处理信息。</b
      >
    </p>
    <p><b>（二）转让</b></p>
    <p>
      1、<b>随着我们业务的持续发展，我们有可能进行合并、收购、资产转让或类似的交易，而您的信息有可能作为此类交易的一部分而被转移。</b>我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则，我们将要求该公司、组织重新向您征求授权同意。
    </p>
    <p>2、在获得您的明确同意后，我们会向第三方转让您的个人信息。</p>
    <p><b>（三）披露</b></p>
    <p>
      我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会披露您的个人信息：
    </p>
    <p>1、根据您的需求，在您明确同意的披露方式下披露您所指定的信息。</p>
    <p>
      2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您信息的情况下，我们可能会依据所要求的信息类型和披露方式披露您的信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求接收方必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。
    </p>
    <p><b>（四）共享、转让、披露信息时事先征得授权同意的例外</b></p>
    <p>以下情形中，共享、转让、披露您的信息无需事先征得您的授权同意：</p>
    <p>1、与国家安全、国防安全直接相关的；</p>
    <p>2、与公共安全、公共卫生、重大公共利益直接相关的；</p>
    <p>3、与刑事侦查、起诉、审判和判决执行等司法或行政执法直接相关的；</p>
    <p>
      4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
    </p>
    <p>5、您自行向社会公众公开的信息；</p>
    <p>
      6、从合法公开披露的信息中收集信息的，如合法的新闻报道、政府信息公开等渠道；
    </p>
    <p>7、与我们履行法律法规规定的义务相关的。</p>
    <p>
      <b
        >根据法律规定，共享、转让、披露经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。</b
      >
    </p>
    <p style="font-weight: 700; font-size: 16px">
      <b>四、我们如何存储和保护个人信息</b>
    </p>
    <p><b>（一）个人信息的存储</b></p>
    <p>
      <b
        >除法律法规或监管部门另有规定外，我们仅在本政策所述目的所必需且最短的时限内存储您的个人信息</b
      >。如我们终止服务或运营，我们将及时停止继续收集您个人信息的活动，同时会遵守相关法律法规要求提前向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理，但法律法规或监管部门另有规定的除外。
    </p>
    <p>
      <b>我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内</b
      >，以下情形除外：
    </p>
    <p>1、法律法规有明确规定；</p>
    <p>2、获得您的授权同意；</p>
    <p>3、您使用的预见app服务涉及跨境，预见app需要向境外提供您的个人信息。</p>
    <p>
      针对以上情形，我们会确保依据国家法律法规和相关监管部门的规定执行，并对您的个人信息提供足够的保护。
    </p>
    <p><b>（二）个人信息的保护</b></p>
    <p>
      我们非常重视信息安全，成立了专门的安全团队，并采取一切合理可行的措施，保护您的个人信息：
    </p>
    <p><b>1、数据安全技术措施</b></p>
    <p>
      我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问、使用、修改，避免数据的损坏或丢失。
    </p>
    <p>
      我们的服务采取了多种加密技术，例如在某些预见app服务中，我们将利用加密技术（例如SSL）对您的个人信息进行加密保存，并通过隔离技术进行隔离，以保护您的个人信息。
    </p>
    <p>
      在个人信息使用时，例如信息展示、信息关联计算，我们会采用多种数据脱敏技术增强信息在使用中的安全性。
    </p>
    <p>
      我们还会采用严格的数据访问权限控制和多重身份认证技术保护个人信息，避免数据被违规使用。
    </p>
    <p><b>2、我们为保护个人信息采取的其他安全措施</b></p>
    <p>
      我们有行业先进的以数据为核心，围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理、产品技术等方面多维度提升整个系统的安全性。我们通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用。
    </p>
    <p>我们通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。</p>
    <p>
      我们还会举办安全和隐私保护培训课程，加强员工的安全意识以及对于个人信息保护政策和相关规程的认识。
    </p>
    <p>
      <b>3、我们仅允许有必要知晓这些信息的</b><b>预见app</b
      ><b
        >员工、合作伙伴访问您的个人信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您的个人信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与</b
      ><b>预见app</b><b>的合作关系。</b>
    </p>
    <p>
      4、互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件或其他服务软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的信息安全。在通过预见app服务与第三方进行沟通或购买商品及服务时，您不可避免的要向交易对方或潜在的交易对方披露自己的信息，如联络方式或者邮政地址等。请您妥善保护自己的信息，仅在必要的情形下向他人提供。
    </p>
    <p>
      5、互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的个人信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致个人信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
    </p>
    <p><b>6、安全事件处置</b></p>
    <p>
      为应对个人信息泄露、损毁和丢失等可能出现的风险，我们制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。我们也为安全事件建立了的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。我们亦定期组织内部培训和应急演练，使得我们的相关工作人员掌握相应的应急处置策略和规程。
    </p>
    <p>
      在不幸发生信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报信息安全事件的处置情况。
    </p>
    <p>
      <b
        >请您理解，由于技术的限制以及风险防范的局限，即便我们已经尽量加强安全措施，也无法始终保证信息百分之百的安全。您需要了解，您接入</b
      ><b>预见app</b
      ><b>服务所用的系统和通讯网络，有可能因我们可控范围外的情况而发生问题。</b>
    </p>
    <p>
      <b>请您务必妥善保管好您的帐号、密码及其他身份要素。您在使用</b
      ><b>预见app</b
      ><b
        >服务时，我们会通过您的帐号、密码及其他身份要素来识别您的身份。一旦您泄露了前述信息，您可能会蒙受损失，并可能对您产生不利。如您发现帐号、密码及/或其他身份要素可能或已经泄露时，请您立即和我们取得联系，以便我们及时采取相应措施以避免或降低相关损失。</b
      >
    </p>
    <p style="font-weight: 700; font-size: 16px">
      <b>五、如何管理您的个人信息</b>
    </p>
    <p><b>（一）访问、更新和删除</b></p>
    <p>我们鼓励您更新和修改您的个人信息以使其更准确有效。</p>
    <p>
      <b>如您想查询、修改或删除您的部分信息，请登录</b><b>预见app</b
      ><b>帐号中心（</b><b>预见app</b><b>邮箱帐号请登陆https://reg.163.com；</b
      ><b>预见app</b
      ><b
        >手机帐号请登陆https://aq.reg.163.com/ydaq/offline#/）以及各单项服务的相关功能页面，我们为您提供了相关操作指引和操作设置。</b
      >对于通过COOKIES或同类技术收集的您的信息，我们还在<b>本政策第二条“我们如何使用COOKIES或同类技术”</b>部分说明了向您提供的选择机制。
    </p>
    <p>
      <b
        >您也可以通过本政策“如何联系我们”章节所列的反馈渠道联系我们为您处理。我们将采取适当的技术手段或联系渠道，使您可以访问、更新和删除自己的个人信息或使用</b
      ><b>预见app</b><b>服务时提供的其他信息，但法律法规另有规定的除外。</b>
    </p>
    <p>
      除法律法规另有规定，当您更正、删除您的个人信息时，我们可能不会立即从备份系统中更正或删除相应的信息，但会在备份更新时更正或删除这些信息。
    </p>
    <p><b>（二）公开与分享</b></p>
    <p>
      我们的多项服务可让您不仅与您的社交网络、也与使用该服务的所有用户公开分享您的相关信息，例如，您在预见app服务中所上传或发布的信息、您对其他人上传或发布的信息作出的回应，通过电子邮件或在预见app服务中不特定用户可见的公开区域内上传或公布您的信息，以及包括与这些信息有关的位置数据和日志信息。只要您不删除您所公开或共享的信息，有关信息可能一直留存在公众领域；即使您删除共享信息，有关信息仍可能由其他用户或不受我们控制的第三方独立地缓存、复制或存储，或由其他用户或该等第三方在公众领域保存。<b
        >如您将信息通过上述渠道公开或共享，由此造成您的信息泄露，我们不承担责任。因此，我们提醒并请您慎重考虑是否通过上述渠道公开或共享您的信息。</b
      >
    </p>
    <p><b>（三）帐号注销</b></p>
    <p>
      我们向您提供帐号注销的途径。在符合我们的服务条款约定条件及国家相关法律法规规定的情况下，<b
        >您可以在</b
      ><b>预见app</b><b>帐号中心按照相应的要求提交帐号注销申请（</b
      ><b>预见app</b><b>邮箱帐号请登陆https://reg.163.com并点击“帐号注销”；</b
      ><b>预见app</b
      ><b
        >手机帐号请登陆https://aq.reg.163.com/ydaq/offline#/并点击“帐号注销”），部分单项服务帐号的注销可能需要您按照该项服务页面提示的具体指引进行操作。</b
      >您也可以选择通过<b>本政策“如何联系我们”章节</b>所列的反馈渠道联系我们，我们会尽快为您作出解答。
    </p>
    <p>
      在您的帐号注销之后，我们将停止为您提供全部或部分预见app服务，并依据您的要求，删除您的个人信息或做匿名化处理，但法律法规另有规定的除外。
    </p>
    <p><b>（四）改变您授权同意的范围</b></p>
    <p>
      您总是可以选择是否向我们披露个人信息。有些个人信息是使用预见app服务所必需的，但大多数其他信息的提供是由您决定的。您可以通过删除信息、关闭设备功能、注销帐号等方式改变您授权我们继续收集信息的范围或撤回您的授权。
    </p>
    <p>
      <b
        >当撤回授权后，我们无法继续为您提供撤回授权所对应的服务，也不再处理您相应的信息。但您撤回授权的决定，不会影响此前基于您的授权而开展的信息处理。</b
      >
    </p>
    <p><b>（五）个人信息副本获取</b></p>
    <p>
      如您需要您的个人信息的副本，您可以通过<b>本政策“如何联系我们”章节</b>所列的反馈渠道联系我们，在核实您的身份后，我们将向您提供您在我们的服务中的个人信息副本(包括基本资料、身份信息)，但法律法规另有规定的或本政策另有约定的除外。
    </p>
    <p><b>（六）响应您的上述请求</b></p>
    <p>
      为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
    </p>
    <p>在以下情形中，我们将无法响应您的请求：</p>
    <p>1、与我们履行法律法规规定的义务相关的；</p>
    <p>2、与国家安全、国防安全直接相关的；</p>
    <p>3、与公共安全、公共卫生、重大公共利益直接相关的；</p>
    <p>4、与刑事侦查、起诉、审判和执行判决等直接相关的；</p>
    <p>5、我们有充分证据表明您存在主观恶意或滥用权利的；</p>
    <p>
      6、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
    </p>
    <p>7、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</p>
    <p>8、涉及商业秘密的。</p>
    <p style="font-weight: 700; font-size: 16px"><b>六、第三方服务</b></p>
    <p>
      预见app服务可能接入或链接至第三方提供的社交媒体或其他服务（包括网站或其他服务形式）。包括：
    </p>
    <p>
      1、您可利用“分享”键将某些预见app服务内容分享到第三方服务，或您将第三方服务内容分享到预见app服务。这些功能可能会收集您的信息（包括您的日志信息），并可能在您的设备内装置COOKIES，从而正常运行上述功能；
    </p>
    <p>
      2、我们通过广告或我们服务的其他方式向您提供链接，使您可以链接至第三方的服务或网站；
    </p>
    <p>
      3、其他接入第三方服务的情形。例如，<b>为实现本政策中声明的目的，我们可能会接入第三方服务商提供的SDK或其他类似的应用程序（如以嵌入代码、插件等形式），以便向您提供更好的客户服务和用户体验。</b>目前，我们接入的第三方服务商主要包括以下几种类型：
    </p>
    <p>（1）用于广告相关服务，包括广告展示、广告数据监测/统计等；</p>
    <p>
      （2）用于消息推送功能，包括手机厂商Push推送、特定事件提醒、个性化内容推荐等；
    </p>
    <p>
      （3）用于支付相关服务，包括订单支付、交易行为核验、收入结算、支付信息汇总统计等；
    </p>
    <p>（4）用于在您同意的情况下获取设备位置权限、搜集设备信息和日志信息等；</p>
    <p>
      （5）用于第三方授权服务，包括第三方帐号登陆、将有关内容分享至第三方产品等；
    </p>
    <p>
      （6）用于支持产品功能模块，包括在线直播、视频播放、语音识别、AR、智能客服、弹幕播放、内容存储等；
    </p>
    <p>
      （7）用于优化产品性能，包括提高硬件配网能力、降低服务器成本、功能热修复等；
    </p>
    <p>
      （8）用于帐号安全、产品加固相关服务，包括网络监测、域名解析、防劫持、反垃圾反作弊、加解密服务等。
    </p>
    <p>
      <b
        >我们接入的部分第三方SDK或类似应用程序可能会收集您的个人信息，如您在我们的服务中使用这类由第三方提供的服务时，您同意将由其直接收集和处理您的信息。</b
      >我们会评估这类第三方服务收集个人信息的合法性、正当性、必要性，要求该等第三方对您的个人信息采取保护措施，并严格遵守相关法律法规与监管要求。<b>您可以点击《第三方SDK目录》，了解我们主要接入的第三方SDK基本情况，各单项服务接入第三方SDK的情况可能有不同，如需了解具体单项服务接入第三方SDK的详细情况，请您前往对应的服务页面并查阅我们各单项服务的具体条款和相关页面。</b>您也可以选择通过<b>本政策“如何联系我们”章节</b>所列的反馈渠道联系我们，我们会尽快为您作出解答。
    </p>
    <p>
      <b
        >以上第三方社交媒体或其他服务由相关的第三方负责运营。您使用该等第三方的社交媒体服务或其他服务（包括您向该等第三方提供的任何信息），须受第三方自己的服务条款及信息保护声明（而非本政策）约束，您需要仔细阅读其条款。本政策仅适用于我们所收集的个人信息，并不适用于任何第三方提供的服务或第三方的信息使用规则。如您发现这些第三方社交媒体或其他服务存在风险时，建议您终止相关操作以保护您的合法权益并及时与我们取得联系。</b
      >
    </p>
    <p style="font-weight: 700; font-size: 16px"><b>七、未成年人保护</b></p>
    <p>
      我们建议：<b>任何未成年人参加网上活动应事先取得父母或其他监护人（以下简称\"监护人\"）的同意。</b>我们将根据国家相关法律法规的规定保护未成年人的相关信息。
    </p>
    <p>
      我们鼓励监护人指导未成年人使用预见app服务。<b
        >如果您是未满十四周岁的未成年人，请您通知您的监护人共同阅读并接受我们的《</b
      ><b>预见app</b><b>儿童个人信息保护规则及监护人须知》&nbsp;并在您使用</b
      ><b>预见app</b><b>服务、提交个人信息之前，寻求其同意和指导。</b>
    </p>
    <p style="font-weight: 700; font-size: 16px"><b>八、修订和通知</b></p>
    <p>
      <b>为了给您提供更好的服务，我们可能会根据</b><b>预见app</b
      ><b
        >服务的更新情况及法律法规的相关要求适时修改本政策的条款，该等修改构成本政策的一部分。我们会在本页面上公布对本政策所作的任何变更。对于重大变更，我们还会提供更为显著的通知（包括网站公告、推送通知、弹窗提示或其他方式），本政策所指的重大变更包括但不限于：</b
      >
    </p>
    <p>
      1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
    </p>
    <p>
      2、我们在所有权结构方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
    </p>
    <p>3、个人信息共享、转让或公开披露的主要对象发生重大变化；</p>
    <p>4、您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
    <p>
      5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生重大变化；
    </p>
    <p>6、其他重要的或可能严重影响您的个人权益的情况发生。</p>
    <p>
      我们的任何修改都会将您的满意度置于首位。我们鼓励您在每次使用预见app服务时都查阅我们的隐私政策。
    </p>
    <p style="font-weight: 700; font-size: 16px"><b>九、如何联系我们</b></p>
    <p>
      我们设立了个人信息保护专职部门，将按照本政策保护您的个人信息。如您有关于网络信息安全的投诉和举报，或您对本政策、预见app的隐私措施、您的信息的相关事宜有任何问题、意见或建议，<b
        >请与</b
      ><b>预见app</b><b>的个人信息保护负责人联系，可发送邮件至47981612@</b
      ><b>qq</b><b>.com或写信至以下地址：</b><b>沈阳市浑南区全运</b
      ><b>5路国际设计谷2楼</b><b>&nbsp;</b><b>李奥</b><b>（收）；邮编：</b
      ><b>10010</b><b>。</b>
    </p>
    <p>
      一般情况下，我们将在收到您的问题、意见或建议，并验证您的用户身份后的十五个工作内予以处理。如您不满意我们的处理，还可以向履行个人信息保护职责的部门进行投诉、举报。
    </p>
    "
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {
    yinsi() {
      window.location.href = "http://sdkdoc.beizi.biz/#/zh-cn/guide/UsePrivacy";
    },
  },
};
</script>

<style>
#app {
  background-color: #fff;
  padding: 10px 5px;
  font-family: "Arial", "Microsoft YaHei", "黑体", "宋体", sans-serif;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 1.5px;
}
</style>